import { ArrowRightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

interface HeadTemplateProp {
  title: string;
  paths: { name: string; path: string }[];
}

export const HeadTemplate = ({ title, paths }: HeadTemplateProp) => {
  return (
    <div className="h-[50vh] bg-slate-800 flex items-center justify-center ">
      <div>
        <div className="text-center text-white text-4xl font-semibold">
          {title}
        </div>
        <div className="flex flex-wrap justify-center items-center text-neutral-50 mt-3 text-xl gap-3">
          {paths?.map((item, i) => (
            <div key={`head-template-item-${i}`}>
              {paths.length === i + 1 ? (
                <div>{item.name}</div>
              ) : (
                <Link to={item.path}>
                  <div className="flex gap-2 hover:text-rose-500 cursor-pointer transition-all duration-500 ">
                    <div className="">{item.name}</div>
                    <div className="flex items-center justify-center">
                      {" "}
                      <ArrowRightOutlined />
                    </div>
                  </div>
                </Link>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
