interface Prop {
    icon: React.ReactNode;
    text: string;
  }
  export default function WhySectionItem({ icon, text }: Prop) {
    return (
      <div className="bg-rose-600 hover:bg-blue-900 transition-all duration-1000 rounded-lg p-4 h-40 flex items-center justify-center">
        <div>
          <div className="flex items-center justify-center">{icon}</div>
          <div className="text-center text-neutral-50 font-semibold mt-3 ">
            {text}
          </div>
        </div>
      </div>
    );
  }
  