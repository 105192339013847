interface OrganizeDataProps {
  icon: React.ReactNode;
  title: string;
  text: string;
}
export const OrganizeTemplate = ({ icon, title, text }: OrganizeDataProps) => {
  return (
    <div className="lg:flex gap-6 mt-8 bg-white dark:bg-gray-900 text-black dark:text-neutral-50">
      <div className="flex items-center justify-center lg:block ">
        <div className="cursor-pointer h-full">{icon}</div>
      </div>{" "}
      <div className="mt-4 lg:mt-0">
        <div className=" font-semibold text-2xl">{title}</div>
        <div className=" mt-3 ">{text}</div>
      </div>
    </div>
  );
};
