import { HeadTemplate } from "../../common/HeadTemplate";
import { ListTemplate } from "../../common/ListTemplate";

interface Prop {
  title: string;
  text: string;
}
const list: Prop[] = [
  {
    title: "Branding Essentials",
    text: `Branding is more than just a logo; it's the essence of your business identity.
     Our team understands the importance of creating a strong brand that resonates with your
      target audience and sets you apart from the competition. We specialize in crafting comprehensive
       branding solutions that encompass everything from visual identity and messaging to brand strategy
        and positioning.`,
  },
  {
    title: "Building a Memorable Brand",
    text: `A successful brand is built on a foundation of consistency, authenticity, and emotional 
    connection. We work closely with you to define your brand's values, personality, and unique selling points,
     ensuring that every aspect of your brand communicates a clear and compelling message to your audience.`,
  },
  {
    title: "Benefits of Effective Branding",
    text: `Effective branding is more than just a logo; it's a powerful tool that can help drive business 
    growth and foster customer loyalty. A strong brand identity can differentiate you from competitors,
     increase brand recognition, and create a lasting impression on your audience. By investing in branding,
      you're investing in the long-term success and sustainability of your business.`,
  },
];

export default function Branding() {
  return (
    <main>
      <HeadTemplate
        title="Branding"
        paths={[
          { name: "Home", path: "/" },
          { name: "Services", path: "/services" },
          { name: "Branding", path: "/services/branding" },
        ]}
      />

      <div className="flex justify-center items-center mt-8 px-6 md:px-0 my-5">
        <div className="md:flex md:w-5/6 gap-4">
          <div className="md:w-1/2 flex items-center">
            <img
              src={"/images/branding-image.jpg"}
              width={1078}
              height={658}
              alt="design"
              className="border border-slate-300 shadow-md shadow-slate-300 rounded"
            />
          </div>

          <div className="md:w-3/6 mt-6 md:mt-0">
            {list.map((item, i) => (
              <ListTemplate {...item} key={`design-item-template-${i}`} />
            ))}
          </div>
        </div>
      </div>
    </main>
  );
}
