import { HeadTemplate } from "../../common/HeadTemplate";
import { ListTemplate } from "../../common/ListTemplate";

interface Prop {
  title: string;
  text: string;
}
const list: Prop[] = [
  {
    title: "App Development Expertise",
    text: `Our team specializes in creating cross-platform mobile 
    applications using React Native. We leverage this framework to build high-quality, 
    native-like mobile apps for both iOS and Android platforms. With our expertise in React Native,
     we ensure that your mobile app is not only visually appealing but also performs seamlessly across 
     different devices and screen sizes.`,
  },
  {
    title: "Languages and Frameworks for Mobile App Development",
    text: `Our developers are proficient in a wide range of languages and
     frameworks crucial for mobile app development, including JavaScript for React Native
      and Dart for Flutter. With React Native, we utilize JavaScript and React to create dynamic 
      and interactive user interfaces, while Flutter enables us to build visually stunning and performant
       apps using Dart. Both frameworks offer a rich set of components and APIs for building cross-platform 
       mobile apps that deliver a native-like experience to users.`,
  },
  {
    title: "Benefits and Support for Mobile App Development",
    text: `By leveraging React Native and Flutter, we deliver mobile applications that are not 
    only visually stunning but also highly functional and user-friendly. Our team provides comprehensive
     support throughout the development process, ensuring that your app meets your requirements and exceeds
      your expectations. With our expertise in mobile app development, we help you reach a wider audience and 
      achieve your business goals.`,
  },
];

export default function Mobile() {
  return (
    <main>
      <HeadTemplate
        title="Mobile App Development"
        paths={[
          { name: "Home", path: "/" },
          { name: "Services", path: "/services" },
          { name: "Mobile App Development", path: "/services/mobile" },
        ]}
      />

      <div className="flex justify-center items-center mt-8 px-6 md:px-0 my-5">
        <div className="md:flex md:w-5/6 gap-4">
          <div className="md:w-1/2 flex items-center">
            <img
              src={"/images/mobile_app_image.jpeg"}
              width={1078}
              height={658}
              alt="design"
              className="border border-slate-300 shadow-md shadow-slate-300 rounded"
            />
          </div>

          <div className="md:w-3/6 mt-6 md:mt-0">
            {list.map((item, i) => (
              <ListTemplate {...item} key={`design-item-template-${i}`} />
            ))}
          </div>
        </div>
      </div>
    </main>
  );
}
