interface Prop {
  title: string;
  text: string;
}
export const ListTemplate = ({ title, text }: Prop) => {
  return (
    <div className="mt-1">
      <div className="text-xl font-medium">{title}</div>
      <div className="mt-1 font-light">{text}</div>
    </div>
  );
};
