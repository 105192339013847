import {
  InstagramOutlined,
  FacebookOutlined,
  LinkedinOutlined,
} from "@ant-design/icons";
import { ReactNode } from "react";
export default function Footer() {
  const footerLink1: { text: string; path: string }[] = [
    { text: "About Us", path: "/aboutus" },
    { text: "Services", path: "/services" },
    { text: "How we work", path: "/works" },
    { text: "contacts", path: "/contacts" },
  ];

  const footerLink2: { text: string; path: string }[] = [
    { text: "UI/UX Design", path: "/services/design" },
    { text: "Web App Development", path: "/services/web" },
    { text: "E-commerce", path: "/services/ecommerce" },
    { text: "Mobile App Development", path: "/services/mobile" },
    { text: "Branding", path: "/services/branding" },
  ];

  const connectData: { item: string; value: string }[] = [
    { item: "General Enquiry", value: "+91 854 7132 243" },
    { item: "Sales Enquiry", value: "+91 944 6987 626" },
    { item: "Email", value: "info@ezrasys.com" },
    { item: "HR Enquiry", value: "hr@ezrasys.com" },
  ];

  return (
    <div className=" bg-slate-200">
      <div className="p-4 flex items-center justify-center">
        <div className="mt-8 min-h-[200px] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4  text-center lg:text-left gap-8 lg-gap-0 relative">
          <div className="h-full  ">
            <FooterHeader text="Company" />
            {footerLink1.map((link, i) => (
              <FooterLink {...link} key={`footer-1-link-${i}`} />
            ))}
          </div>

          <div className="h-full  ">
            <FooterHeader text="Services" />
            {footerLink2.map((link, i) => (
              <FooterLink {...link} key={`footer-2-link-${i}`} />
            ))}
          </div>
          <div className="h-full  ">
            <div>
              <FooterHeader text="Ezrasys" />
            </div>
            <div className="mt-2"> Door No 47/764 H</div>
            <div className="mt-2"> Muscat Tower</div>
            <div className="mt-2"> Pc Road Chalikavattom</div>
            <div className="mt-2"> Vytilla, PIN - 682028</div>
            <div className="mt-2"> Ernakulam, Kerala, India</div>
          </div>

          <div className="h-full  ">
            <FooterHeader text="Connect" />
            {connectData.map((data, i) => (
              <ConnectItem {...data} key={`connect-row-${i}`} />
            ))}
          </div>
        </div>
      </div>
      <div className="gap-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 p-1  justify-between items-center mt-8 w-full text-slate-800">
        <div className="flex items-center justify-center order-3 lg:order-1 md:col-span-2 lg:col-span-1 ">EZRASYS &copy; 2024 All rights reserved</div>
        <div className=" flex items-center justify-center gap-3 order-2">
          <div>Privacy Policy </div>
          <div>|</div>
          <div>Terms & Condition</div>
        </div>
        <div className=" flex items-center justify-center  gap-8 order-1 lg:order-3">
          <SocialMediaIcon link="https://www.instagram.com/invites/contact/?i=1qrwgzl8ualoe&utm_content=mfew80y">
            <InstagramOutlined className="text-3xl hover:text-red-800 " />
          </SocialMediaIcon>
          <SocialMediaIcon link="https://www.facebook.com/ezrasys?mibextid=2JQ9oc">
            <FacebookOutlined className="text-3xl hover:text-blue-600" />
          </SocialMediaIcon>
          <SocialMediaIcon link="https://www.linkedin.com/company/ezrasys-technologies/">
            <LinkedinOutlined className="text-3xl hover:text-blue-900" />
          </SocialMediaIcon>
        </div>
      </div>
    </div>
  );
}
const FooterHeader = ({ text }: any) => {
  return <div className="text-xl font-medium">{text}</div>;
};
const FooterLink = ({ text, path }: { text: string; path: string }) => {
  return (
    <div className="mt-2 hover:text-blue-700 transition-all duration-700">
      <a href={path}>{text}</a>
    </div>
  );
};

const ConnectItem = ({ item, value }: { item: string; value: string }) => {
  return (
    <div className="flex mt-2 items-center gap-3">
      <div className="w-[130px]">{item}</div>
      <div>:</div>
      <div>{value}</div>
    </div>
  );
};

const SocialMediaIcon = ({
  children,
  link,
}: {
  link: string;
  children: ReactNode;
}) => {
  return (
    <a href={link} className="cursor-pointer border">
      {children}
    </a>
  );
};
