import { HeadTemplate } from "../../common/HeadTemplate";
import { ListTemplate } from "../../common/ListTemplate";

interface Prop {
  title: string;
  text: string;
}
const list: Prop[] = [
  {
    title: "E-commerce Development Expertise",
    text: `We specialize in crafting premium e-commerce solutions
     that elevate your online store. Our team of expert developers leverages
      cutting-edge technologies and industry best practices to deliver bespoke digital 
      solutions tailored to your unique requirements.`
  },
  {
    title: "Languages and Frameworks for E-commerce",
    text: `Our team is proficient in a wide range of languages and 
    frameworks crucial for e-commerce development, including PHP, React, Next.js,
     Angular, Node.js, WordPress, and Shopify. PHP serves as the backbone for server-side 
     logic and dynamic content generation, while front-end technologies like React, Next.js, 
     and Angular are utilized for building interactive and responsive user interfaces. Node.js 
     powers our backend development, providing a scalable and efficient runtime environment for 
     building fast and reliable web applications. Additionally, our expertise in WordPress and 
     Shopify enables us to deliver highly customizable and extendible e-commerce solutions.`
  },
  {
    title: "Benefits and Support for E-commerce",
    text: `By leveraging a combination of these technologies and frameworks,
     we deliver e-commerce platforms that are not only visually stunning but
      also highly functional and user-friendly, providing seamless shopping experiences for your customers.`
  }
];

export default function Ecommerce() {
  return (
    <main>
      <HeadTemplate
        title="E-Commerce"
        paths={[
          { name: "Home", path: "/" },
          { name: "Services", path: "/services" },
          { name: "E-commerce", path: "/ecommerce" },
        ]}
      />

      <div className="flex justify-center items-center mt-8 px-6 md:px-0 my-5">
        <div className="md:flex md:w-5/6 gap-4">
          <div className="md:w-1/2 flex items-center">
            <img
              src={"/images/ecommerce_image.jpg"}
              width={1078}
              height={658}
              alt="design"
              className="border border-slate-300 shadow-md shadow-slate-300 rounded"
            />
          </div>

          <div className="md:w-3/6 mt-6 md:mt-0">
            {list.map((item, i) => (
              <ListTemplate {...item} key={`design-item-template-${i}`} />
            ))}
          </div>
        </div>
      </div>
    </main>
  );
}
