import { Link } from "react-router-dom";
import { CloseOutlined, CaretDownOutlined } from "@ant-design/icons";
import { useState } from "react";

export const MobileNav = ({ menus, onClose }: any) => {
  return (
    <div className="fixed top-0 left-0 w-screen h-screen z-50 bg-white ">
    <div className="px-4 p-2 h-[85px] flex items-center justify-between  shadow-lg shadow-black/10">
      <Link to={"/"}>
        <img
          src={"/images/ezra-logo.png"}
          width={150}
          height={80}
          alt="logo"
        />
      </Link>
      <div className="cursor-pointer" onClick={onClose}>
        <CloseOutlined className=" font-extrabold text-xl" />
      </div>
    </div>
    {menus.map((item: any, i: number) => (
      <LinkItem onClose={onClose} key={`menu-nav-sm-item-${i}`} item={item} />
    ))}
  </div>
  );
};

const LinkItem = ({ item,onClose }: any) => {
    const className = `text-rose-600 hover:font-semibold transition-all duration-500 hover:bg-rose-600 hover:text-neutral-50 cursor-pointer hover:border-b border-b-2 border-black/15`;
    const [showSubItems, setShowSubItems] = useState(false);
    return (
      <div className={!item.child ? className : ""}>
        {item.child ? (
          <div
            className={`${
              item.child ? className : ""
            } p-5 flex items-center gap-2`}
            onClick={() => item.child && setShowSubItems((p) => !p)}
          >
            {item.title}
            {item.child && (
              <div className="flex items-center">
                <CaretDownOutlined />
              </div>
            )}
          </div>
        ) : (
          <Link to={item.path} onClick={onClose}>
            <div
              className={`${
                item.child ? className : ""
              } p-5 flex items-center gap-2`}
              onClick={() => item.child && setShowSubItems((p) => !p)}
            >
              {item.title}
            </div>
          </Link>
        )}
        {item.child?.map((val: any, j: number) => (
          <Link to={val.path} key={`menu-nav-sm-item-sub-${j}-${j}`} onClick={onClose}>
            <div
              className={`${item.child ? className : ""} ${
                showSubItems ? "p-5  opacity-100" : "opacity-0"
              } transition-all duration-500 `}
            >
              <div
                className={`flex items-center gap-2 ${
                  showSubItems ? "block" : "hidden"
                }`}
              >
                {val.title}
              </div>
            </div>
          </Link>
        ))}
      </div>
    );
  };