import { HeadTemplate } from "../../common/HeadTemplate";

export default function Services() {
  return (
    <main>
      <HeadTemplate
        title="Services"
        paths={[
          { name: "Home", path: "/" },
          { name: "Services", path: "/services" },
        ]}
      />

      <div className="flex justify-center items-center mt-8">
        <div className="flex w-5/6">
          <div className="w-1/2">
            <img
              src={"/images/uiuximage.jpg"}
              width={1078}
              height={658}
              alt="design"
            />
          </div>

          <div className="w-3/6">
            <div className="mt-1 text-3xl font-medium">UI/UX Design</div>
            <div className="mt-4">
              Ezrasys offers UI/UX design services, focusing on creating
              user-friendly interfaces and seamless experiences for digital
              products. Their expertise likely includes user research,
              wireframing, prototyping, and visual design to enhance usability
              and engagement. If you're interested in their services, you might
              want to reach out to them directly for more details or to discuss
              your specific project needs.
            </div>
          </div>
        </div>
      </div>
      <div className="border border-solid  mt-8 border-slate-300"></div>
      <div className="flex justify-center items-center mt-12">
        <div className="flex w-5/6">
          <div className="w-3/6">
            <div className="mt-1 text-3xl font-medium">Web App Development</div>
            <div className="mt-4 pr-8">
              Ezrasys web app development services offer a comprehensive
              approach to building custom web applications tailored to each
              client's unique requirements. From initial discovery and design to
              development, testing, deployment, and ongoing support, Ezrasys
              ensures a seamless and collaborative process. With expertise in
              full-stack development, responsive design, scalability,
              performance, security, and compliance, Ezrasys delivers
              high-quality web applications that meet the highest standards.
              Clients benefit from transparent communication, expert guidance,
              and a commitment to customer satisfaction, making Ezrasys the
              ideal partner for turning ideas into successful digital solutions.
            </div>
          </div>
          <div className="w-1/2">
            <img
              src={"/images/web_app_development.jpeg"}
              width={1078}
              height={658}
              alt="design"
            />
          </div>
        </div>
      </div>

      <div className="border border-solid  mt-8 border-slate-300"></div>
      <div className="flex justify-center items-center mt-12">
        <div className="flex w-5/6 gap-8">
          <div className="w-1/2">
            <img
              src={"/images/ecommerce.jpeg"}
              width={924}
              height={1286}
              alt="design"
            />
          </div>
          <div className="w-3/6">
            <div className="mt-1 text-3xl font-medium">E-commerce</div>
            <div className="mt-4">
              Ezrasys ecommerce application service is a platform designed to
              facilitate online retail operations for businesses. It offers a
              range of features including inventory management, order
              processing, payment integration, and customer relationship
              management. By using Ezrasys, businesses can streamline their
              online sales processes, improve customer experience, and expand
              their reach in the digital marketplace. With easy setup and
              integration with popular ecommerce platforms, Ezrasys provides a
              user-friendly solution for businesses looking to establish or
              enhance their online presence.
            </div>
          </div>
        </div>
      </div>

      <div className="border border-solid  mt-8 border-slate-300"></div>
      <div className="flex justify-center items-center mt-12">
        <div className="flex w-5/6 gap-8">
          <div className="w-3/6">
            <div className="mt-1 text-3xl font-medium">
              Mobile App Development
            </div>
            <div className="mt-4">
              Ezrasys ecommerce application service is a platform designed to
              facilitate online retail operations for businesses. It offers a
              range of features including inventory management, order
              processing, payment integration, and customer relationship
              management. By using Ezrasys, businesses can streamline their
              online sales processes, improve customer experience, and expand
              their reach in the digital marketplace. With easy setup and
              integration with popular ecommerce platforms, Ezrasys provides a
              user-friendly solution for businesses looking to establish or
              enhance their online presence.
            </div>
          </div>
          <div className="w-1/2">
            <img
              src={"/images/mobile_dev.png"}
              width={980}
              height={600}
              alt="design"
            />
          </div>
        </div>
      </div>

      <div className="border border-solid  mt-8 border-slate-300"></div>
      <div className="flex justify-center items-center mt-12">
        <div className="flex w-5/6 gap-8">
          <div className="w-1/2">
            <img
              src={"/images/branding.jpeg"}
              width={580}
              height={300}
              className="h-[350px]"
              alt="design"
            />
          </div>
          <div className="w-3/6">
            <div className="mt-1 text-3xl font-medium">Branding</div>
            <div className="mt-4">
              Elevate your brand with Ezrasys Technologies. Our expert team
              crafts strategic branding solutions tailored to your business
              objectives. From captivating design to compelling messaging, we
              ensure consistency across all touchpoints. With years of
              experience and a commitment to innovation, we're ready to help you
              unlock your brand's full potential. Contact us today to get
              started.
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
