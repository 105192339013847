interface ServiceList {
  title: string;
  imageUrl: string;
  text: string;
  redirectUrl: string;
}
export const serviceList: ServiceList[] = [
  {
    title: "UI/UX Design",
    imageUrl: " url('/images/service2.jpg')",
    text: `
    UI/UX Design combines aesthetic appeal with intuitive functionality to create
     digital experiences that delight users. It involves understanding user needs and behaviors to
     design interfaces that are both visually pleasing and easy to navigate....`,
    redirectUrl: "/services/design",
  },
  {
    title: "Web App Development",
    imageUrl: " url('/images/service1.jpg')",
    text: `Web App Development involves creating dynamic applications that run
     on web browsers, offering users a seamless experience across various devices.
      It encompasses the process of designing, coding, testing, and deploying web-based
      software solutions to 
    address specific needs or provide services over the internet…`,
    redirectUrl: "/services/web",
  },
  {
    title: "E-commerce",
    imageUrl: " url('/images/service3.jpg')",
    text: `E-commerce app development is the process of designing,
     building, and launching mobile applications tailored for online 
     buying and selling. It involves integrating features like product 
     listings, secure payment gateways, and 
    user-friendly interfaces to facilitate smooth transactions 
    and enhance the overall shopping experience for customers`,
    redirectUrl: "/services/ecommerce",
  },
  {
    title: "Mobile App Development",
    imageUrl: " url('/images/service5.jpg')",
    text: `
    Mobile app development is the process of creating software
     applications specifically designed to run on mobile devices,
      such as smartphones and tablets. It involves various stages
       including conceptualization, design, development, testing,
        and deployment, 
    with the goal of providing users with valuable and engaging experiences
     tailored to the mobile platform`,
    redirectUrl: "/services/mobile",
  },
  {
    title: "Branding",
    imageUrl: " url('/images/service4.jpg')",
    text: `Branding is the strategic process of shaping and defining the identity, values, 
    and perception of a company, product, or service. It involves creating a cohesive visual
     and emotional experience that resonates
     with the target audience, building trust, loyalty, and recognition within the market.`,
    redirectUrl: "/services/branding",
  },
  
];
