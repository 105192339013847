import { HeadTemplate } from "../common/HeadTemplate";

export default function About() {
  return (
    <main>
      <HeadTemplate
        title="About Us"
        paths={[
          { name: "Home", path: "/" },
          { name: "About Us", path: "/about" },
        ]}
      />

      <div className=" mt-8 font-light px-8">
        <div className=" grid grid-cols-1 md:grid-cols-2 w-full gap-1">
          <div className="px-8 flex items-center  justify-center">
            <img
              src={"/images/about-us.jpeg"}
              width={1078}
              height={658}
              alt="design"
              className="border border-slate-300 shadow-md shadow-slate-300 rounded"
            />
          </div>

          <div className="mt-10 md:mt-0">
            <div className="mt-2">
              <span className="font-medium text-rose-500">
                Ezrasys Technologies,
              </span>{" "}
              established in 2018, is a leading software development company
              specializing in web application, mobile app development, branding,
              e-commerce solutions, and UI/UX design. With a focus on innovation
              and customer satisfaction, we leverage cutting-edge technologies
              to create intuitive and user-friendly software products that meet
              the unique needs of our clients. Our team of experienced
              professionals is dedicated to delivering high-quality solutions
              that drive business growth and enhance user experiences. At
              Ezrasys, we are committed to excellence in every project we
              undertake, ensuring that our clients receive top-notch services
              tailored to their specific requirements.
            </div>

            <div>
              In the realm of web application development, we excel in creating
              robust, scalable, and secure web-based solutions tailored to our
              clients' needs. Whether it's a simple website or a complex
              enterprise-level application, we leverage the latest technologies
              and industry best practices to deliver high-performance web
              applications that drive business growth.
            </div>
          </div>

          <div className="mt-5 md:mt-10">
            <div>
              Our mobile app development services encompass both iOS and Android
              platforms, where we craft user-friendly and feature-rich
              applications that resonate with our clients' target audience. From
              conceptualization to deployment, we ensure seamless user
              experiences and adherence to quality standards throughout the
              development lifecycle.
            </div>
            <div>
              In branding, we help businesses establish a strong identity and
              market presence through strategic branding initiatives. From logo
              design to brand messaging, we work closely with our clients to
              create cohesive brand identities that leave a lasting impression
              on their audience.
            </div>
            <div>
              For e-commerce solutions, we provide end-to-end services including
              platform selection, custom development, payment gateway
              integration, and ongoing support. Our goal is to empower
              businesses to succeed in the competitive e-commerce landscape by
              delivering scalable and user-friendly online storefronts.
            </div>
            <div>
              In the realm of UI/UX design, we prioritize user-centered design
              principles to create intuitive and visually appealing interfaces.
              Through meticulous research, wireframing, prototyping, and user
              testing, we ensure that our designs not only look great but also
              provide seamless and engaging user experiences.
            </div>
          </div>
        </div>
      </div>

      <div className="flex items-center justify-between sm:justify-center px-8 sm:px-4 md:px-0 h-[100px] mt-8 bg-slate-200 text-xl sm:text-2xl md:text-4xl gap-8 font-semibold sm:font-light">
        <div className="">4+ Years Experience</div>
        <div className="">25+ Projects</div>
      </div>

      <div className="text-4xl text-rose-500 mt-8 flex items-center justify-center text-center px-8">
        OUR GEOGRAPHY
      </div>
      <div className="flex items-center justify-center mt-4 px-8">
        <div className=" text-center">
          We cooperate with customers from many countries and the geography of
          our cooperation is constantly expanding. This map shows the number of
          projects, that we have fulfilled for our clients from different
          countries.
        </div>
      </div>
      <div className="mt-6 px-8 flex items-center justify-center">
        <img src={"/images/map.svg"} alt="map" width={800} height={600} />
      </div>
    </main>
  );
}
