import { useEffect, useState } from "react";
import { Parallax } from "react-scroll-parallax";

interface Prop {
  text: string;
}
export function TypeSectionWrapper({ text }: Prop) {
  const [firstSectionProgress, setFirstSectionProgress] = useState(0);
  const [firstSectionText, setFirstSectionText] = useState("");
  useEffect(() => {
    const count = Math.floor((firstSectionProgress - 0.1) * 2 * text.length);
    const textToShow = text.slice(0, count);
    setFirstSectionText(textToShow);
  }, [firstSectionProgress]);
  return (
    <Parallax
      className="h-[calc(3*100vh)] px-[1.875rem]"
      onProgressChange={(p) => setFirstSectionProgress(p)}
    >
      <div
        className={`text-center flex items-center justify-center
         h-screen w-full mt-0  text-4xl lg:text-5xl  font-bold space font-[SpaceGrotesk]
         transition-all duration-500
         ${
          firstSectionProgress > 0 ? "sticky top-0" : "static"
        } ${firstSectionProgress < 0.4 ? 'text-slate-500':firstSectionProgress > 0.5 ?'text-rose-700':'text-slate-800'}`}
      >
        {firstSectionText}
      </div>
    </Parallax>
  );
}
