import {
  DollarOutlined,
  AppstoreOutlined,
  UserOutlined,
  LikeOutlined,
  StarOutlined,
} from "@ant-design/icons";
interface WhySectionData {
  icon: React.ReactNode;
  text: string;
}
const className = "text-4xl text-neutral-50";
export const whySectionData: WhySectionData[] = [
  {
    icon: <DollarOutlined className={className} />,
    text: "Cost-Effective Solutions",
  },
  {
    icon: <AppstoreOutlined className={className} />,
    text: "Comprehensive Services",
  },
  {
    icon: <UserOutlined className={className} />,
    text: "Client-focused Approach",
  },

  {
    icon: <StarOutlined className={className} />,
    text: "Premium Products",
  },
  {
    icon: <LikeOutlined className={className} />,
    text: "Unparalleled Support",
  },
];
