import { Parallax } from "react-scroll-parallax";
import { ArrowDownOutlined } from "@ant-design/icons";
import { whySectionData } from "../datas/whySectionData";
import WhySectionItem from "../common/WhySectionItem";
import { TypeSectionWrapper } from "../common/TypeSectionWrapper";
import { organizeData } from "../datas/organizeData";
import { OrganizeTemplate } from "../common/OrganizeTemplate";
import { serviceList } from "../datas/serviceData";
import ServiceTemplate from "../common/ServiceTemplate";

const Home = () => {
  const initialFirstSectionText =
    "Share your thoughts we make your brand expensive";
  const handleClick = () => {
    window.scrollBy({
      top: window.innerHeight - 85,
      behavior: "smooth",
    });
  };

  return <main className="bg-white dark:bg-gray-900">
      <div 
      style={{ backgroundImage: `url('/images/coworkers-team.jpg')` }}
      className="-z-20 p-4 flex items-center justify-center bg-center h-screen bg-cover bg-blend-darken">
      <Parallax speed={-20}>
          <div className=" w-full">
            <div className="text-neutral-50 font-medium text-center text-3xl font-[SpaceGrotesk] transition-all duration-700 hover:scale-125 hover:font-bold">
              Vision · Execution · Success
            </div>
            <div className="rotate-on-load mt-6 w-full text-neutral-50 font-bold text-center text-4xl md:text-5xl lg:text-6xl ">
              MORE THAN 25 <br />
              REALIZED PROJECTS
            </div>
            <div className="flex w-full mt-12 justify-center">
              <ArrowDownOutlined
                className="hover:text-rose-600  text-neutral-50  text-6xl font-bold animate-bounce-custom  cursor-pointer"
                onClick={handleClick}
              />
            </div>
          </div>
        </Parallax>
        
      </div>

      <div className="p-2 mt-16">
        <div className="text-4xl text-black dark:text-neutral-50 font-semibold text-center">
          WHY EZRASYS ?
        </div>
        <div className="mt-8 px-12 grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4  xl:grid-cols-5 gap-4">
          {whySectionData.map((item, i) => (
            <WhySectionItem {...item} key={`why section item ${i}`} />
          ))}
        </div>
      </div>

      <TypeSectionWrapper text={initialFirstSectionText} />
      
      <div className="p-2 mt-20">
        <div className="text-4xl text-black dark:text-neutral-50 font-semibold text-center">
          WE ORGANIZE THE PROCESS
        </div>

        <div className="flex items-center justify-center ">
          <div className="grid lg:grid-cols-2 sm:w-8/12 w-10/12 gap-6 mt-8 ">
            {organizeData.map((item, i) => (
              <OrganizeTemplate key={`organize-data-item-${i}`} {...item} />
            ))}
          </div>
        </div>
      </div>

      <div className="p-2 mt-16">
        <div className="text-4xl text-black dark:text-neutral-50 font-semibold text-center">
          OUR SERVICES
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 mt-6 px-[1.875rem] gap-8">
          {serviceList.map((item, i) => (
            <ServiceTemplate {...item} key={`service-grid-item-${i}`} />
          ))}
        </div>
      </div>
      
      <div className="h-[200px]">

      </div>

  </main>;
};

export default Home;
