import { HeadTemplate } from "../../common/HeadTemplate";
import { ListTemplate } from "../../common/ListTemplate";

interface Prop {
  title: string;
  text: string;
}
const list: Prop[] = [
  {
    title: "User-Centric Design",
    text: `We believe that the key to successful UI/UX development 
    lies in putting the user first. That's why we start every project
     by conducting thorough user research to gain insights into your target
      audience's preferences, pain points, and behaviors. Armed with this knowledge,
       we design interfaces and experiences that resonate with your users and drive 
       meaningful interactions.`,
  },
  {
    title: "Iterative Prototyping",
    text: `We follow an iterative design process, which allows us to quickly prototype 
    and test different design concepts. This iterative approach enables us to gather
     feedback early and often, ensuring that we're continuously refining and improving
      the user experience based on real user insights.`,
  },
  {
    title: "Responsive Design",
    text: `In today's multi-device world, it's essential that your digital experiences are accessible
     and optimized across all devices and screen sizes. That's why we
      prioritize responsive design, ensuring that your UI/UX adapts seamlessly
       to desktops, tablets, and smartphones, providing a consistent and engaging
        experience across all platforms.`,
  },
];
export default function Design() {
  return (
    <main>
      <HeadTemplate
        title="UI/UX Design"
        paths={[
          { name: "Home", path: "/" },
          { name: "Services", path: "/services" },
          { name: "UI/UX Design", path: "/design" },
        ]}
      />

      <div className="flex justify-center items-center mt-8 px-6 md:px-0 my-5">
        <div className="md:flex md:w-5/6 gap-4">
          <div className="md:w-1/2 flex items-center">
            <img
              src={"/images/design_main_image.jpeg"}
              width={1078}
              height={658}
              alt="design"
              className="border border-slate-300 shadow-md shadow-slate-300 rounded"
            />
          </div>

          <div className="md:w-3/6 mt-6 md:mt-0">
            {list.map((item, i) => (
              <ListTemplate {...item} key={`design-item-template-${i}`} />
            ))}
          </div>
        </div>
      </div>
    </main>
  );
}
