import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ParallaxProvider } from "react-scroll-parallax";
import HeaderLayout from "./common/HeaderLayout";
import Home from "./pages/Home";
import Footer from "./common/Footer";
import About from "./pages/About";
import HowWeWorks from "./pages/HowWeWorks";
import Contacts from "./pages/Contacts";
import Services from "./pages/services/Services";
import Design from "./pages/services/Design";
import Web from "./pages/services/Web";
import Ecommerce from "./pages/services/Ecommerce";
import Mobile from "./pages/services/Mobile";
import Branding from "./pages/services/Branding";

const App = () => {
  return (
    <ParallaxProvider>
      <Router>
        <HeaderLayout />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/works" element={<HowWeWorks />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/services" element={<Services />} />
          <Route path="/services/design" element={<Design />} />
          <Route path="/services/web" element={<Web />} />
          <Route path="/services/ecommerce" element={<Ecommerce />} />
          <Route path="/services/mobile" element={<Mobile />} />
          <Route path="/services/branding" element={<Branding />} />
        </Routes>
      </Router>
      <Footer />
    </ParallaxProvider>
  );
};

export default App;
