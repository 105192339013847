import { Button, Form, Input, Select, Modal } from "antd";
import { useState } from "react";
import { HeadTemplate } from "../common/HeadTemplate";

type FieldType = {
  first_name?: string;
  last_name?: string;
  email?: string;
  phone_number?: string;
  service?: string;
  budget_range?: string;
};

export default function Contacts() {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const onSubmit = (e: any) => {
    setIsLoading(true);
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer Amez077",
      },
      body: JSON.stringify(e),
    };
    fetch("https://api.ezrasys.com/submit_form.php", options)
      .then((response) => {
        setIsLoading(false);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        form.resetFields();
        setShowSuccessModal(true);
      })
      .catch(console.log);
  };
  return (
    <main>
      <Modal
        footer={null}
        open={showSuccessModal}
        onCancel={() => setShowSuccessModal(false)}
      >
        <div className="h-[200px] w-[full] flex items-center justify-center text-xl text-rose-600  ">
          We've received your inquiry. Our team will contact you soon!
        </div>
      </Modal>
      <HeadTemplate
        title="Contacts"
        paths={[
          { name: "Home", path: "/" },
          { name: "Contacts", path: "/contacts" },
        ]}
      />
      <div className="mt-12 text-rose-500 flex items-center justify-center text-xl font-medium ">
        CONTACT WITH US
      </div>
      <div className="text-center mt-2 text-3xl font-semibold">
        WRITE US A MESSAGE{" "}
      </div>
      <div className="text-center text-lg mt-5 px-8">
        If you have any questions, please do not hesitate and fill in the form
        below. We will contact you ASAP.
      </div>
      <Form onFinish={onSubmit} autoComplete="off" form={form}>
        <div className="flex justify-center mt-8 ">
          <div className="w-full lg:w-5/6 xl:4/6 px-8">
            <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="w-[100]">
                First Name
                <Form.Item<FieldType> name="first_name">
                  <Input
                    className="border border-slate-400 h-[40px]"
                    placeholder="First Name"
                  />
                </Form.Item>
              </div>
              <div>
                Last Name
                <Form.Item<FieldType> name="last_name">
                  <Input
                    className="border border-slate-400 h-[40px]"
                    placeholder="Last Name"
                  />
                </Form.Item>
              </div>
              <div>
                Email
                <Form.Item<FieldType> name="email">
                  <Input
                    className="border border-slate-400 h-[40px]"
                    placeholder="Email"
                  />
                </Form.Item>
              </div>
              <div>
                Number
                <Form.Item<FieldType> name="phone_number">
                  <Input
                    className="border border-slate-400 h-[40px]"
                    placeholder="Number"
                  />
                </Form.Item>
              </div>
              <div className="">
                <div>Service</div>
                <Form.Item<FieldType> name="service">
                  <Select
                    placeholder="Select Service"
                    className="w-full border border-slate-400 h-[40px] rounded"
                    options={[
                      { label: "UI/UX design", value: "UI/UX design" },
                      {
                        label: "Website development",
                        value: "Website development",
                      },
                      { label: "Web Application", value: "Web Application" },
                      {
                        label: "Mobile Application",
                        value: "Mobile Application",
                      },
                      { label: "E-commerce", value: "E-commerce" },
                      { label: "Branding", value: "Branding" },
                    ]}
                  />
                </Form.Item>
              </div>

              <div className="">
                <div>Budget Range</div>
                <Form.Item<FieldType> name="budget_range">
                  <Select
                    placeholder="Select Budget"
                    className="w-full border border-slate-400 h-[40px] rounded"
                    options={[
                      { label: "Below $2000 ", value: "below2000" },
                      { label: "Below $6000", value: "below6000" },
                      { label: "Below $10000", value: "below10000" },
                      { label: "Above $10000", value: "above10000" },
                    ]}
                  />
                </Form.Item>
              </div>
            </div>

            <div className="my-4 flex justify-end">
              <Button
                loading={isLoading}
                className="bg-rose-500 hover:!bg-rose-500 text-neutral-50 hover:!text-neutral-50 hover:!border-rose-500 font-medium "
                htmlType="submit"
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </Form>
    </main>
  );
}
