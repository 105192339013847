import { ArrowRightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

interface Props {
  title: string;
  imageUrl: string;
  text: string;
  redirectUrl: string;
}
export default function ServiceTemplate({
  imageUrl,
  title,
  text,
  redirectUrl,
}: Props) {
  let navigate = useNavigate();
  const className = `w-full my-6 text-white font-semibold text-2xl p-4 h-[85px] bg-cover rounded rotate-x-custom  hover:shadow hover:shadow-black 
     transition-all  delay-300 flex items-end justify-start`;
  return (
    <div
      className="service-template-card p-1 px-5 cursor-pointer pb-10 bg-neutral-50"
      onClick={() => navigate(redirectUrl)}
    >
      <div style={{ backgroundImage: imageUrl }} className={className}>
        {title}
      </div>
      <div className="mt-4 ">{text}</div>
      <div className="mt-3 flex items-center gap-2 cursor-pointer  service-template-card-link transition-all  delay-200  w-[130px] font-medium">
        {" "}
        Learn more <ArrowRightOutlined />{" "}
      </div>
    </div>
  );
}
