import {
  LineChartOutlined,
  CodeSandboxOutlined,
  LaptopOutlined,
  RocketOutlined,
} from "@ant-design/icons";

interface OrganizeDataProps {
  icon: React.ReactNode;
  title: string;
  text: string;
}

const className = "text-5xl text-rose-600";

export const organizeData: OrganizeDataProps[] = [
  {
    icon: <LineChartOutlined className={className} />,
    title: "ANALYTICS",
    text: `You come to us with your unique idea and we will discuss and
        analyze all the details. After precise analysis we deliver the
        plan with the best solutions.`,
  },
  {
    icon: <CodeSandboxOutlined className={className} />,
    title: "PROTOTYPING & DESIGN",
    text: `After detailed planning based on your project concept we will
    start developing the product and create a high-quality design,
    taking into account the direction and type.`,
  },
  {
    icon: <LaptopOutlined className={className} />,
    title: "PROGRAMMING",
    text: `Our programmers use the latest development technologies to bring
    your ideas to life, create solutions that will work effectively
    and bring you profit and satisfaction.`,
  },
  {
    icon: <RocketOutlined className={className} />,
    title: "TESTING & IMPROVEMENT",
    text: `Each our project must pass the QA testing phase to make sure our
    clients will receive only the high-quality products.`,
  },
];
