import {
  OneToOneOutlined,
  MobileOutlined,
  BoxPlotOutlined,
  BgColorsOutlined,
  LineChartOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import { HeadTemplate } from "../common/HeadTemplate";
import { organizeData } from "../datas/organizeData";
import { OrganizeTemplate } from "../common/OrganizeTemplate";

interface WorksTemplateProps {
  Component: any;
  title: string;
  text: string;
}
const works: WorksTemplateProps[] = [
  {
    Component: BgColorsOutlined,
    title: "UI/UX design",
    text: "Premium design solutions that amplify brand identity with finesse.",
  },
  {
    Component: OneToOneOutlined,
    title: "Web apps",
    text: "Web applications, internal systems, social networks",
  },
  {
    Component: MobileOutlined,
    title: "Mobile apps",
    text: "Mobile applications for iOS and Android",
  },
  {
    Component: BoxPlotOutlined,
    title: "Websites",
    text: "Corporative websites, highloaded resources, Landings, responsive design",
  },
  {
    Component: ShoppingCartOutlined,
    title: "E-commerce",
    text: "It offers premium design and user-friendly features for seamless shopping.",
  },
  {
    Component: LineChartOutlined,
    title: "Branding",
    text: "Tailored branding solutions with a focus on premium design aesthetics.",
  },
];
export default function HowWeWorks() {
  return (
    <main className="bg-white dark:bg-gray-900 text-black dark:text-neutral-50 py-4">
      <HeadTemplate
        title="How We Work"
        paths={[
          { name: "Home", path: "/" },
          { name: "Works", path: "/works" },
        ]}
      />

      <div className="mt-12 text-rose-500 flex items-center justify-center text-xl font-medium ">
        ANY IDEAS?
      </div>
      <div className="text-center mt-2 text-2xl font-semibold">
        WE WILL IMPLEMENT THEM!
      </div>
      <div className="flex items-center justify-center mt-8 px-8">
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 md:w-5/6">
          {works.map((item, i) => (
            <WorksTemplate {...item} key={`work-gid-item-${i}`} />
          ))}
        </div>
      </div>

      <div className="flex items-center justify-center mt-12">
        <div className="mt-8 grid md:grid-cols-2 w-10/12 gap-6">
          {organizeData.map((item, i) => (
            <OrganizeTemplate {...item} key={`organize-data-item-${i}`} />
          ))}
        </div>
      </div>
    </main>
  );
}

const WorksTemplate = ({ Component, title, text }: WorksTemplateProps) => {
  return (
    <div className="flex gap-3 p-2">
      <div>
        <Component className="text-4xl" />
      </div>
      <div>
        <div className="text-xl font-medium">{title}</div>
        <div className="font-light mt-1">{text}</div>
      </div>
    </div>
  );
};
