import { useLayoutEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { MenuOutlined, CaretDownOutlined } from "@ant-design/icons";
import { MobileNav } from "./MobileNav";

const menus = [
  {
    title: "HOME",
    path: "/",
  },
  { title: "ABOUT US", path: "/about" },
  {
    title: "SERVICES",
    path: "/services",
    child: [
      { title: "UI/UX Design", path: "/services/design" },
      { title: "Web App Development", path: "/services/web" },
      { title: "E-commerce", path: "/services/ecommerce" },
      { title: "Mobile App Development", path: "/services/mobile" },
      { title: "Branding", path: "/services/branding" },
    ],
  },
  { title: "HOW WE WORK", path: "/works" },
  { title: "CONTACTS", path: "/contacts" },
];

const HeaderLayout = () => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState<string | undefined>();
  const [isScrolled, setIsScrolled] = useState(false);
  const [showMobNavbar, setShowMobNavbar] = useState(false);
  const handleScroll = () => {
    const position = window.scrollY;
    if (position > 85) setIsScrolled(true);
    else setIsScrolled(false);
  };

  useLayoutEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useLayoutEffect(() => {
    setActiveTab(location.pathname);
  }, [location]);

  return (
    <div
      className={`${isScrolled ? "fixed bg-white" : "absolute"} w-full  z-50`}
    >
      <div
        className={`p-2 max-w-[100svw] h-[85px] flex justify-between items-center transition-all duration-500 ${
          isScrolled && "bg-white"
        }`}
      >
        <div>
          <Link to={"/"}>
            <img
              src={isScrolled?"/images/ezra-logo.png":"/images/ezra-logo-white.png"}
              width={150}
              height={80}
              alt="logo"
            />
          </Link>
        </div>

        <ul className="hidden  lg:flex px-2 items-center  justify-around gap-4 w-8/12 font-semibold ">
          {menus.map((item, i) => (
            <li
              key={`header-item-${i}`}
              className={`grow group ${
                activeTab !== item.path &&
                (isScrolled ? "text-slate-900" : "text-neutral-50")
              } relative cursor-pointer hover:text-rose-600 transition-all duration-500 ${
                activeTab == item.path && "text-rose-600"
              } `}
            >
              <div className="flex items-center gap-1">
                <Link to={item.path}> {item.title}</Link>
                {item.child && <CaretDownOutlined />}
              </div>
              {item.child && <ChildMenuWrapper paths={item.child} />}
            </li>
          ))}
        </ul>

        <div
          className="flex lg:hidden m-4"
          onClick={() => setShowMobNavbar(true)}
        >
          <MenuOutlined
            className={`${
              isScrolled ? "text-neutral-900" : "text-neutral-50"
            } font-semibold  hover:font-bold text-2xl cursor-pointer`}
          />
        </div>

      </div>

      {showMobNavbar && (
        <MobileNav menus={menus} onClose={() => setShowMobNavbar(false)} />
      )}

    </div>
  );
};

export default HeaderLayout;

const ChildMenuWrapper = ({
  paths,
}: {
  paths: { title: string; path: string }[];
}) => {
  return (
    <div className="absolute mt-1 overflow-hidden transition-all shadow-lg shadow-black/20 border border-black/15 duration-500 opacity-0 group-hover:opacity-100  bg-neutral-100 min-w-[170px] text-nowrap rounded-md">
      {paths.map((item, i) => (
        <div
          key={`react-nav-child-${i}`}
          className={`h-[40px] ${
            i > 0 && "border-t"
          } border-slate-300 flex items-center px-3 hover:text-white text-rose-600 transition-all duration-300 hover:bg-rose-500 hover:font-semibold`}
        >
          <Link to={item.path}>{item.title}</Link>
        </div>
      ))}
    </div>
  );
};
