import { HeadTemplate } from "../../common/HeadTemplate";
import { ListTemplate } from "../../common/ListTemplate";

interface Prop {
  title: string;
  text: string;
}
const list: Prop[] = [
  {
    title: "Premium Full Stack Web Application Development",
    text: `We specialize in crafting premium web applications and full-stack websites 
    that elevate your online presence. Our team of expert developers leverages cutting-edge
     technologies and industry best practices to deliver bespoke digital solutions tailored 
     to your unique requirements.`,
  },
  {
    title: "Languages and Frameworks Expertise",
    text: `Our team is proficient in a wide range of languages and
     frameworks, including PHP, React, Next.js, Angular, Node.js, WordPress, and Shopify.PHP serves as the backbone 
     for server-side logic and dynamic content generation, while front-end technologies like React,
      Next.js, and Angular are utilized for building interactive and responsive user interfaces; Node.js
       powers our backend development, providing a scalable and efficient runtime environment for building fast
        and reliable web applications, and our expertise in WordPress and Shopify enables us to deliver highly 
        customizable and extendible solutions for the app`,
  },
  {
    title: "Benefits and Support",
    text: `By leveraging a combination of these technologies and frameworks, 
    we deliver web applications and websites that are not only visually stunning 
    but also highly functional and user-friendly`,
  },
];

export default function Web() {
  return (
    <main>
      <HeadTemplate
        title="Web App Development"
        paths={[
          { name: "Home", path: "/" },
          { name: "Services", path: "/services" },
          { name: "Web App Development", path: "/web" },
        ]}
      />

      <div className="flex justify-center items-center mt-8 px-6 md:px-0 my-5">
        <div className="md:flex md:w-5/6 gap-4">
          <div className="md:w-1/2 flex items-center">
            <img
              src={"/images/web_development_img.jpg"}
              width={1078}
              height={658}
              alt="design"
              className="border border-slate-300 shadow-md shadow-slate-300 rounded"
            />
          </div>

          <div className="md:w-3/6 mt-6 md:mt-0">
            {list.map((item, i) => (
              <ListTemplate {...item} key={`design-item-template-${i}`} />
            ))}
          </div>
        </div>
      </div>
    </main>
  );
}
